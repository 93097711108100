import { BsFillStarFill } from "react-icons/bs";
import "./Reviews.css";
import { useState } from "react";
import moment from "moment";
import AxiosCall from "../../../../controller/axios";
import toast from "react-hot-toast";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useAuth } from "../../../../contexts/AuthProvider";

export const Reviews = ({ productId, comments }) => {
    const [openModal, setOpenModal] = useState(false)
    const [uploadingImages, setUploadingImages] = useState(false);
    const [loading, setLoading] = useState('');
    const [activeModalImage, setActiveModalImage] = useState('');
    const { auth, setIsLoginModalOpen } = useAuth();
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        rating: 4,
        images: [],
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleImageUpload = async (e) => {
        const files = e.target.files;
        const formData = new FormData();
        setUploadingImages(true);

        // Add all files to the form data for upload
        for (let i = 0; i < files.length; i++) {
            formData.append("images", files[i]);
            // formData.append("upload_preset", "your-upload-preset"); // For Cloudinary
        }

        try {
            // Upload images to Cloudinary (or your API)
            const response = await AxiosCall({
                method: 'post',
                data: formData,
                path: 'api/products/pushimgs'
            })

            const images = response.data.images;

            setFormData((prevData) => ({
                ...prevData,
                images: [...prevData.images, ...images],
            }));

            setUploadingImages(false);
        } catch (error) {
            console.error("Image upload failed:", error);
            setUploadingImages(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!auth.isAuth) return setIsLoginModalOpen(true)
        try {
            setLoading(true)
            const res = await AxiosCall({
                method: 'post',
                path: "api/products/comments",
                data: { ...formData, productId }
            })

            console.log("Review uploaded successfully:", res);
            setLoading(false)
            setFormData({
                title: "",
                description: "",
                images: [],
            })
            toast.success(`Review Uploaded successfully :)`);
            setLoading(false)
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        } catch (error) {
            setLoading(false)
            console.error("Error uploading comments:", error);
            toast.error("Error uploading comments " + error?.response?.data?.errors[0]);
        }
    };
    return (
        <div
            className="x-reviews x-content">
            <div className="x-container">
                <h3>Verified Customer Feedback</h3>
                <div>
                    What customers are saying
                </div>
                {comments?.map((c) => (
                    <div className="x-review-post">
                        <div className="x-review-img">
                            <img src={c.images[0]} onClick={() => setActiveModalImage(c.images[0])} />
                            <Lightbox
                                open={activeModalImage ? true : false}
                                close={() => setActiveModalImage("")}
                                slides={c.images.map(i => ({ src: i }))}
                            />
                        </div>
                        <div className="x-content">
                            <p className="x-ratings"><BsFillStarFill color={"orange"} />
                                <BsFillStarFill color={c?.rating > 0 ? "orange" : "grey"} />
                                <BsFillStarFill color={c?.rating > 1 ? "orange" : "grey"} />
                                <BsFillStarFill color={c?.rating > 2 ? "orange" : "grey"} />
                                <BsFillStarFill color={c?.rating > 3 ? "orange" : "grey"} />
                                <BsFillStarFill color={c?.rating > 4 ? "orange" : "grey"} /></p>
                            <p className="x-title">Very good product</p>
                            <p className="x-description">{c.description}</p>
                            <p><small>{moment().format('DD-mm-yyyy')}  By {c?.creatorId?.firstName} {c?.creatorId?.lastName}</small></p>
                        </div>


                    </div>
                ))}
                <div className="x-box">
                    <h3>Post a comment</h3>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input placeholder="Enter a title. e.g. Very good product" type="text" name="title"

                                onChange={handleInputChange}
                                required />
                        </div>
                        <div>
                            <textarea placeholder="Tell us your thoughts about this product" name="description" rows={10}

                                onChange={handleInputChange}
                                required />
                        </div>
                        <div className="name-container">
                            <input type="file" multiple onChange={handleImageUpload} required />
                            {uploadingImages && <p>Uploading images...</p>}
                            <ul>
                                {formData.images.map((image, index) => (
                                    <li key={index}>
                                        <img src={image} alt="Uploaded" width="50px" />
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <p className="x-rate">
                            <span>Rate this product: </span>
                            <BsFillStarFill color={formData.rating > 0 ? "orange" : "grey"} onClick={() => setFormData({ ...formData, rating: 1 })} />
                            <BsFillStarFill color={formData.rating > 1 ? "orange" : "grey"} onClick={() => setFormData({ ...formData, rating: 2 })} />
                            <BsFillStarFill color={formData.rating > 2 ? "orange" : "grey"} onClick={() => setFormData({ ...formData, rating: 3 })} />
                            <BsFillStarFill color={formData.rating > 3 ? "orange" : "grey"} onClick={() => setFormData({ ...formData, rating: 4 })} />
                            <BsFillStarFill color={formData.rating > 4 ? "orange" : "grey"} onClick={() => setFormData({ ...formData, rating: 5 })} />
                        </p>
                        <input type="submit" />
                    </form>
                </div>
            </div>
        </div>
    )
}
