import "./Signup.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { useState } from "react";

import React from "react";
import { useAuth } from "../../../contexts/AuthProvider.js";
import { signupService } from "../../../services/auth-services/signupService";
import { toast } from "react-hot-toast";
import { useData } from "../../../contexts/DataProvider.js";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from "../../../utils/index.js";
import { gaTrackEvent } from "../../../utils/analytics.js";
import { RiDivideFill } from "react-icons/ri";
import { Footer } from "../../../components/Footer/Footer.jsx";

export const Signup = () => {
  const { loading } = useData();

  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const { setAuth, loginHandler, error, setError } = useAuth();

  const navigate = useNavigate();

  const [signupCredential, setSignupCredential] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
  });

  const signupHandler = async () => {
    try {
      setSignUpLoading(true);
      setError("");
      // if (signupCredential.password === signupCredential.confirmPassword) {
      const response = await signupService({
        email: signupCredential.email,
        password: signupCredential.password,
        firstName: signupCredential.firstName?.split(' ')[0],
        lastName: signupCredential.firstName?.split(' ')[1]
      }
      );
      const { user, token } = response.data
      setSignUpLoading(false);
      gaTrackEvent({ type: 'sign_up', label: "Form", category: 'User' })
        window.fbq('track', 'CompleteRegistration');
        toast.success(
        `You've successfully signed up, ${user.firstName}`
      );

      setAuth({
        ...user,
        token,
        isAuth: true,
      });

      localStorage.setItem("token", token);
      localStorage.setItem("isAuth", true);
      localStorage.setItem("user", JSON.stringify(user));
      // localStorage.setItem("firstName", firstName);
      // localStorage.setItem("lastName", lastName);
      // localStorage.setItem("email", email);

      navigate("/");
      // } else setError("Password do not match")
    } catch (error) {
      setSignUpLoading(false);
      console.log(error)
      setError(error.response?.data?.errors[0]);
    } finally {
      setSignUpLoading(false);
    }
  };

  return (
    <>
      {!loading && (
      <div className="signup-container">
        <h2>Sign Up</h2>
        <center>
            <GoogleLogin
               
              width={"250px"}
                 
              useOneTap={true}
              auto_select={true}
              theme="outline"
              ux_mode="popup" 
                onSuccess={credentialResponse => {
                  loginHandler({ oauthToken: credentialResponse.credential })
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              /></center>
              <br />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            signupHandler();
          }}
          className="signup-body"
        >
          <div className="email-container">
            <label htmlFor="email">Email Address</label>
            <input
              required
              onChange={(e) =>
                setSignupCredential({
                  ...signupCredential,
                  email: e.target.value,
                })
              }
              id="email"
              placeholder="Enter Email"
              type="email"
            />
          </div>
          <div className="name-container">
            <label htmlFor="first-name">Full Name</label>
            <input
              onChange={(e) =>
                setSignupCredential({
                  ...signupCredential,
                  firstName: e.target.value,
                })
              }
              id="first-name"
              placeholder="Enter Full Name"
              type="text"
            />
          </div>

          {/* <div className="name-container">
            <label htmlFor="last-name">Last Name</label>
            <input
              onChange={(e) =>
                setSignupCredential({
                  ...signupCredential,
                  lastName: e.target.value,
                })
              }
              id="last-name"
              placeholder="Enter Last Name"
              type="text"
            />
          </div> */}

          <div className="password-container">
            <label htmlFor="password">Password</label>
            <div className="input-container">
              <input
                required
                onChange={(e) =>
                  setSignupCredential({
                    ...signupCredential,
                    password: e.target.value,
                  })
                }
                id="password"
                minLength="4"
                placeholder="Enter Password"
                type={hidePassword ? "password" : "text"}
              />{" "}
              {!hidePassword ? (
                <BsEye
                  className="hide-show-password-eye"
                  onClick={() => setHidePassword(!hidePassword)}
                />
              ) : (
                <BsEyeSlash
                  className="hide-show-password-eye"
                  onClick={() => setHidePassword(!hidePassword)}
                />
              )}
            </div>
          </div>

          {/* <div className="confirm-password-container">
            <label for="confirm-password">Confirm Password</label>
            <div className="input-container">
              <input
                required
                id="confirm-password"
                onChange={(e) =>
                  setSignupCredential({
                    ...signupCredential,
                    confirmPassword: e.target.value,
                  })
                }
                minLength="5"
                placeholder="Enter Password Again"
                type={hidePassword ? "password" : "text"}
              />{" "}
              {!hidePassword ? (
                <BsEye
                  className="hide-show-password-eye"
                  onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                />
              ) : (
                <BsEyeSlash
                  className="hide-show-password-eye"
                  onClick={() => setHidePassword(!hidePassword)}
                />
              )}
            </div>
          </div> */}


          <div className="remember-me-container">
            <div>
              <input required name="remember-me" type="checkbox" />
              <label htmlFor="remember-me">
                I accept all terms and conditions
              </label>
            </div>
          </div>
          {error && <p className="error">{error}</p>}

          <div className="signup-btn-container">
            <input value="Sign Up" type="submit" />
            {/* <button
              onClick={(e) => {
                loginHandler(e, "testacct@gmail.com", "testacct");
              }}
            >
              Login with Test Credentials
            </button> */}
          </div>

          <Link to="/login">Already have an account? Click to Sign in</Link>
        </form>
      </div>
      )}
      <Footer />
    </>
  );
};
