import "./PromoModal.css";
import React from "react";

import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";

export const PromoModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {setIsLoginModalOpen} = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      setIsModalOpen(true)
    }, 20000);
  }, [])


  return (
    isModalOpen &&
    <div className="promo-modal-container">
      <div className="promo-input-container">
        <div className="promo-content">
          {/* <div className="promo-img">
            <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1722445000/pexels-lastly-699122_pjqnes.jpg" />
          </div> */}
          <div className="promo-details">
            <p>Enjoy an instant</p>
            <h2>₦10,000 COUPON</h2>
            <p>On your first order. Signup now and enjoy our earlybird benefits. Valid till Sept 30th</p>
            <br />
            <button
              className="explore-btn"
            onClick={() => {
              setIsModalOpen(false)
              setIsLoginModalOpen(true)
            }}
            >
            Continue
            </button>
          </div>
          <div
            className="cross-tab-icon cross-tab-icon-mobile"
            onClick={() => setIsModalOpen(false)}
          >
            <RxCross2 color={"rgb(106, 106, 65)"} size={25} />
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  );
};
