import React from "react";
import { useAuth } from "../../../contexts/AuthProvider.js";
import { Logout } from "../../auth/Logout/Logout";
import "./Profile.css";
import { Helmet } from "react-helmet";

export const Profile = () => {
  const { auth } = useAuth();

  return (
    <>
     <Helmet>
        <title>360gadgetsafrica - Profile</title>
        <meta name="Shop your favourite gadgets and accessories" />
      </Helmet>
    <div className="profile-container">
      <div className="profile-details">
        <div className="name">
          <span>Full Name: </span>
          <span>
            {" "}
            {auth.firstName} {auth.lastName}{" "}
          </span>
        </div>

        <div className="email">
          <span>Email: </span>
          <span> {auth.email} </span>
        </div>
      </div>
      <Logout />
    </div>
    </>
  );
};
