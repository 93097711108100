import "./HeroVideo.css";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

import React, { useState } from "react";
import { RiShoppingBag3Line } from "react-icons/ri";
import { gaTrackEvent } from "../../../../utils/analytics";

export const HeroVideo = () => {
  const Navigate = useNavigate();
  const [search, setSearch] = useState('')
  const submit  = (e) => {
    e.preventDefault();
    gaTrackEvent({type: 'Search', label: 'Search', data: {title: search}})
    window.fbq('track', 'Search');
    Navigate("/product-listing?title="+ search)

  }
  return (
    <div className="hero-video-container">
      <div className="hero-video">
        {/* <ReactPlayer
          url={`${process.env.PUBLIC_URL}/assets/videos/herovideo2.git`}
          playing
          playbackRate={1.5}
          muted
          loop
          controls={false}
          width={"100%"}
          height={"100%"}
        /> */}
        <img width={"100%"} height={'100%'}
          src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1722379166/IMG_1124_2_k7plqh.jpg"
        // src={`https://images.unsplash.com/photo-1609334761848-77b4d1994040?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`} 
        />
      </div>

      <div className="hero-text">
        <h1><RiShoppingBag3Line />We're Now Open! </h1>
        {/* <h2>Enjoy low prices, Great deals and 24hours super fast delivery to your city</h2> */}
        <div className="hero-search">
          <form className="hero-form" onSubmit={submit} >
            <input onChange={(e)=>setSearch(e.target.value)} required placeholder="Search for phones, laptops and accessories" />
            <button >Search</button>
          </form>
        </div>
      </div>


      {/* <button
        onClick={() => Navigate("product-listing")}
        className="shop-now-btn"
      >
        Shop Now
      </button> */}
    </div>
  );
};
