// import "./Contactus.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";

export const Contactus = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>360gadgetsafrica - Contact Us</title>
        <meta name="We'd love to hear from you! Whether you have questions, feedback, or need assistance, our team is here to help" />
      </Helmet>
      <div className="policy-container">
        <div className="policy-heading">
          <h2>Contact us</h2>
          <h3>Lets talk about everything.</h3>
        </div>
        <div className="policy-desc">
          <p> We'd love to hear from you! Whether you have questions, feedback, or need assistance, our team is here to help. Reach out to us through any of the following methods:</p>
        </div>
        <div className="policy-heading">
          <h3>Email</h3>
        </div>
        <div className="policy-desc">
          <p>For general inquiries, support, or feedback, please email us at:</p>
          <p>support@360gadgets.com</p>

        </div>
        <div className="policy-heading">
          <h3>Phone</h3>
        </div>
        <div className="policy-desc">
          <p>Need immediate assistance? Call our customer service line during our business hours:</p>
          <p>+2348039938596</p>
          <p>Monday - Friday: 9AM - 5PM (WAT)</p>
          <p>Monday - Friday: 9AM - 5PM (WAT)</p>
          <p>Plot 7 Ajimotokan Estate, Iyana-dopemu, Lagos.</p>
        </div>
        <div className="policy-heading">
          <h3>Social Media</h3>
        </div>
        <div className="policy-desc">
          <p>Connect with us on social media! Send us a message or leave a comment, and we'll get back to you as soon as possible.</p>
          <ul>
            <li>Facebook: facebook.com/360gadgetsafrica</li>
            <li>Instagram: instagram.com/360gadgetsafrica</li>
            <li>Twitter: twitter.com/360gadgetsafrica</li>
          </ul>
        </div>

        <br />
        <br />
        <button
          onClick={() => navigate(-1)}
          className="explore-btn"
        >
          Back
        </button>
      </div>
      <Footer />
    </>
  );
};
