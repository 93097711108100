import React, { useState } from "react";
import { useUserData } from "../../../contexts/UserDataProvider.js";
import "./Orders.css";
import { closePaymentModal, FlutterWaveButton, useFlutterwave } from 'flutterwave-react-v3';
import { addOrders, updateOrders } from "../../../services/order-services/index.js";
import toast from "react-hot-toast";
import { useAuth } from "../../../contexts/AuthProvider.js";
import { CgArrowRightO } from "react-icons/cg";

export const Orders = () => {
  const { auth, setCurrentPage } = useAuth();
  const { userDataState } = useUserData();
  const config = {
    public_key: 'FLWPUBK-e7ec25431085f5c97d020a92e92b8ab7-X',
    tx_ref: Date.now(),
    amount: 0,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      name: auth.firstName || localStorage.getItem("firstName"),
      email: auth.email || localStorage.getItem("email"),
    },
    customizations: {
      title: '360gadgetsafrica',
      description: 'Payment for items',
      logo: 'https://res.cloudinary.com/dzvhmiutm/image/upload/v1723338006/logo_qt3of8.png',
    },
  }
  const fwConfig = (_id, amount) => ({
    ...config, amount,
    text: <span className="payment-btn-text"> Pay </span>,
    callback: async (response) => {
      console.log(response, _id, amount)
      if (response.status == 'successful' || response.status == "completed") {
        try {
          const order = {
            flutterwave: {
              transaction_id: response.transaction_id,
              tx_ref: response.tx_ref,
            },
            _id
          }
          const res = await updateOrders(order)
          toast.success("Your payment was successful. you will be contacted shortly");
          setTimeout(() => {
            window.location.reload()
          }, 2000);
        } catch (error) {
          toast("An error has occured please contact us for additional support");
        }
      }
      closePaymentModal() // this will close the modal programmatically
    },
    onClose: () => {

    },
  });

  // const handleFlutterPayment =  useFlutterwave(config);
 
  const cancel = async (order) => {
    try {
      const res = await updateOrders(order)
      toast.success("Your payment was cancelled succesfully.");
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }catch (error) {
      toast("An error has occured please contact us for additional support");
    }
  
   
  }

  return !userDataState.orders?.length ? (
    <div className="orders-container">No Orders</div>
  ) : (
    <div className="orders-container">
      {userDataState.orders?.map(
        ({
          amountPaid,
          deliveryAddress,
          trackingId,
          orderedProducts,
          flutterwave,
          _id,
          status
        }) => (
          <div key={trackingId} className="ordered-items-card">
            {flutterwave?.transaction_id == flutterwave?.tx_ref && status != 'cancelled' ?
              <div className="order-id-container">
              <span>Awaiting Payment: </span>
              <small>Please complete your payment to proceed for pickup</small> 
            </div>
            :
            <div className="order-id-container">
              <span>Tracking ID: </span>
              <span>{trackingId}</span> 
            </div>}
            <div className="payment-id-container">
              <span>Status: </span>
              <span>{status || "Pending"}</span>
            </div>
            <div className="price-container">
              <span>Amount: </span>
              <span>₦{amountPaid}</span>
            </div>
            {flutterwave?.transaction_id != flutterwave?.tx_ref &&
              <div className="price-container">
                <span>Delivery-Address:</span>
                <span>
                  {deliveryAddress?.street} {deliveryAddress?.state}{" "}
                  {deliveryAddress?.country}
                </span>
              </div>}
            {flutterwave?.transaction_id == flutterwave?.tx_ref &&
              <>
                <div className="price-container">
                  <b>Pickup address:</b>
                  <span>
                    His Grace Plaza, 14 Francis Oremeji St, Ikeja, 101233, Lagos
                  </span>
                  <a href="https://maps.app.goo.gl/XteHMHUwDpzEpukc6" target="_blank" >Locate on map</a>
                </div>
              {flutterwave?.transaction_id != flutterwave?.tx_ref && <div className="price-container">
                  <b>Contact phone: +2348039938596</b>
                </div>}
              </>
            }
            <div className="products-container">
              {orderedProducts?.map(
                ({ productId: { images, title, discounted_price }, price, id, qty, size }) => (
                  <div className="products-card" key={id}>
                    <img src={images[0]} alt={title} />
                    <div className="description">
                      <span className="name">Name: {title}</span>
                      {size && <span className="qty">Size: {size}</span>}
                      <span className="qty">Qty: {qty}</span>
                      <span className="price">Price: ₦{price}</span>

                    </div>
                  </div>
                )
              )}
              {(status != 'delivered' && status != 'cancelled' ) &&
              flutterwave?.transaction_id == flutterwave?.tx_ref &&
                <div className="payment-btn-group">
                   <div className="add-address-btn-container">
                    <FlutterWaveButton {...fwConfig(_id, amountPaid)} />
                  </div>
                  <button onClick={()=>cancel({
                      flutterwave,
                      _id, status: 'cancelled'
                  })}>Cancel</button>
                </div>}
            </div>
          </div>
        )
      )}
    </div>
  );
};
