import React from "react";
import ReactPlayer from "react-player";
import "./VideosSection.css";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";

export const VideosSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="video-container">
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card" >
          <div>
          <img onClick={()=>navigate('/product-details/antidrop-24343')} 
          src="https://www.bare-cases.com/cdn/shop/products/BareArmour-MinimalistShockResistantCasewithMagSafeforiPhone13Pro_e2a29213-9e87-4dbe-99e1-d2e62ad8c546.jpg?crop=center&height=1200&v=1632817059&width=1200"
          />
          <h3>Anti drop case</h3>
          <span className="notch"></span>
          </div>
        </Tilt>{" "}
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card">
          <div onClick={()=>navigate("/product-details/ps5-slim-8942")} >
          <img  src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1723505824/hob73rffzujc6dkhqelu.jpg"/>
          <h3>PS5 Slim</h3>
          <span className="notch"></span>
          </div>
        </Tilt>
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card">
          
        <div onClick={()=>navigate('/product-details/Iwatch-S4-44MM-GPS-and-Watch-only-38713')} >
          <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1723505824/joafmmiygzmqili2e4z7.jpg" alt="" className="src" />
          <h3>Apple Iwatch </h3>
          <span className="notch"></span>
          </div>
        </Tilt>
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card">
        <div onClick={()=>navigate('/product-details/iPhone-12-Pro-128GB-FU--38207')}> 
         <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1723506857/pl98nojjfjhlywdjprrx.jpg" alt="" className="src" />
          <h3>Iphone 12 pro </h3>
          <span className="notch"></span>
          </div>
        </Tilt>
        
      </div>
    </>
  );
};
