import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { loginService } from "../services/auth-services/loginService";
import { gaTrackEvent } from "../utils/analytics";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState("profile");

  const [loginCredential, setLoginCredential] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const encodedToken = localStorage.getItem("token");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const email = localStorage.getItem("email");
  const navigate = useNavigate();
  const location = useLocation();

  const [auth, setAuth] = useState({});
  useEffect(()=>{
    if( encodedToken){
     setAuth( { token: encodedToken, isAuth: true, firstName, lastName, email })
    } else {
      setAuth( { token: "", isAuth: false })
    } 
  }, [])

  const loginHandler = async ({e, email, password, oauthToken, appleToken}) => {
    try {
    e?.preventDefault();
    setLoginLoading(true);
      setError("");
      setLoginCredential({ email, password, });
      const response = await loginService({email, password, oauthToken, appleToken});
      const { user, token}  = response.data
      setLoginLoading(false);
        toast.success(`Welcome back, ${user.firstName}!`);
        setAuth({
          token,
          isAuth: true,
          ...user
        });
        gaTrackEvent({type: 'sign_in', label: oauthToken ? "GoogleBtn": appleToken ? "AppleBtn":  "Form", category: "User"})
        window.fbq('track', 'CompleteRegistration');
        localStorage.setItem("token", token);
        localStorage.setItem("isAuth", true);
        localStorage.setItem("firstName", user?.firstName);
        localStorage.setItem("lastName", user?.lastName);
        localStorage.setItem("email", user?.email);
        setLoginCredential({ email: "", password: "" });

        window.location.assign(location?.state?.from.pathname || "/");
    } catch (error) {
      setLoginLoading(false);
      console.log(error, 'err')
      setError(error.response?.data?.errors[0]);
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        loginHandler,
        error,
        setError,
        loginLoading,
        setLoginLoading,
        loginCredential,
        setLoginCredential,
        setCurrentPage,
        currentPage,
        setIsLoginModalOpen,
        isLoginModalOpen
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
