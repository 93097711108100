/* eslint-disable no-undef */
/* eslint-disable no-useless-catch */
import Axios from 'axios'; 

// export const baseURL = "http://localhost:4000";
export const baseURL = "https://360gadgetsafrica.com";

const AxiosCall = async callObj => {

  const { path, method, data, contentType, baseUrlType = null } = callObj;
  const token = window.localStorage.getItem('token');

  const headers = {
    Authorization: `Bearer ${token}`,
    // 'Content-Type': contentType || 'application/json'
  };

  let url = `${baseURL}/${path}`;


  try {
    const response = await Axios({ method, url, data, headers, json: true });
    const result = response && response.data;    
    return result;
  } catch (error) {
    throw error;
  }
};
export default AxiosCall;
